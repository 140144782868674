import React from 'react'; 

import { App } from 'components';
import { Navbar, Footer } from './components';

import './style.scss';

interface IProps {}

const Layout: React.FC<IProps> = props => {
  const { children } = props;
  return (
    <App>
      <div className='layout'>
        <Navbar variant='landing' />
        {children}
        <Footer />
      </div>
    </App>
  );
};

export default Layout;

const menuLinks = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'The Card',
    href: '/card',
  },
  {
    text: 'Investing',
    href: '/investing',
  },
  {
    text: 'About',
    href: '/about',
  },
  {
    text: 'Careers',
    href: '/careers',
  },
];

export default menuLinks;

import React from 'react';

import Mask from 'images/hero/mask.inline.svg';
import MaskMobile from 'images/hero/mask-mobile.inline.svg';
import EmailIcon from 'images/icons/email.inline.svg';

import { Image } from 'components';

import './styles/index.scss';

interface IProps {}

const Hero: React.FC<IProps> = props => {

  return (
    <div className={`
      hero
      relative
      pb-8
      bg-orange-500
      text-white
      overflow-hidden
    `}>
      {/* Masks - top rounded white rectangle */}
      <div className='hidden sm:block'>
        <Mask className='hero__mask hero__mask--large absolute z-1' />
      </div>
      <div className='sm:hidden'>
        <MaskMobile className='hero__mask hero__mask--small absolute z-1' />
      </div>

      <div className='container relative z-2'>
        <div className='flex flex-wrap md:flex-no-wrap md:space-x-8'>
          <div className='w-full md:w-8/12 lg:w-7/12'>
            <div className='section'>
              {/* Caption */}
              <div className='mb-10 md:mb-12'>
                <h1>Get the latest information about Plendy</h1>
                <p className='text-lg'>
                  In order to find out about Plendy credit card or investment methods, please leave your email address. You will receive a letter in the mail with all the detailed information.
                </p>
              </div>
              
              {/* Form */}
              <form>
                <div className='flex flex-wrap'>
                  <div className='w-full md:order-2'>
                    {/* Buttons Invest/Card */}
                    <div className='flex flex-wrap md:space-x-8 mb-2'>
                      <button type='button' className='btn-default btn-sm w-full md:w-32'>To invest</button>
                      <button type='button' className='btn-default btn-sm w-full md:w-32 active'>A card</button>
                    </div>
                  </div>
                  <div className='w-full md:order-1'>
                    {/* Input Group */}
                    <div className='input-group mb-5'>
                      <div className='input-with-icon'>
                        <EmailIcon className='input-icon' />
                        <input type='email' className='input mb-2' placeholder='Enter your E-mail' />
                      </div>
                      <button className='btn-secondary w-full md:w-auto' type='submit'>
                        Join Waitlist
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              
            </div>
          </div>
          <div className='w-full md:w-4/12 lg:w-5/12 mt-12 md:mt-0'>
            <Image
              className='hero__plendy-cards-img'
              src='hero/plendy-credit-cards.png'
              alt='Plendy credit cards'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

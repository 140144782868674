import React from 'react';

interface IProps {}

const Footer: React.FC<IProps> = props => {

  return (
    <div className='container'>
      {/* <h2>Footer</h2> */}
    </div>
  );
};

export default Footer;

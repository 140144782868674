import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// Fetch images query
const queryImages = graphql`
	query {
		allFile {
			edges {
				node {
					relativePath
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`;

interface IProps {
  src: string;
	alt?: string;
	className?: string;
}

const Image: React.FC<IProps> = props => {
  const { src, alt, className } = props;
    	
	// Query all images
	const data = useStaticQuery(queryImages);
  
	// Get images array
	const images = data.allFile.edges;
	
	// Find needed image by name (source)
	const img = images.find(item => {
		return item.node.relativePath === src
	});

	// Return optimized gatsby image
  return (
    <Img
      fluid={img.node.childImageSharp.fluid}
			alt={alt}
			className={className}
    />
  );
};

export default Image;

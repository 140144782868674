// Use App.tsx to add providers (theme, state etc)
import React, { Fragment } from 'react';

// Fonts
import 'assets/fonts/index.scss';

// Tailwind Styles
import 'styles/tailwind/tailwind-compiled.css';

interface IProps {}

const App: React.FC<IProps> = props => {
  const { children } = props;
  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export default App;

/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface IProps {
  title?: string;
  description?: string;
}

const Seo: React.FC<IProps> = props => {
  const { title, description } = props;

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						keywords
						siteUrl
						lang
						image
						metaImage {
							width
							height
						}
					}
				}
			}
		`
	);

	const metaTitle = title || site.siteMetadata.description;
	const metaDescription = description || site.siteMetadata.description;
	const metaAuthor = site.siteMetadata.author;
	const metaImage = site.siteMetadata.metaImage;
	const metaLang = site.siteMetadata.lang;
	const metaSiteUrl = site.siteMetadata.siteUrl;
	const metaBanner = metaSiteUrl + site.siteMetadata.image;

	return (
		<Helmet
			htmlAttributes={{
				metaLang,
			}}
			title={metaTitle}
			titleTemplate={`${metaTitle} - ${metaAuthor}`}
			meta={
				[
					{
						name: `description`,
						content: metaDescription,
					},
					{
					name: "keywords",
					content: site.siteMetadata.keywords.join(","),
					},
					{
						property: `og:title`,
						content: metaTitle,
					},
					{
						property: `og:description`,
						content: metaDescription,
					},
					{
						property: `og:type`,
						content: `website`,
					},
					{
						property: "og:image",
						content: metaBanner,
					},
					{
						property: "og:image:width",
						content: metaImage.width,
					},
					{
						property: "og:image:height",
						content: metaImage.height,
					},
					{
						name: "twitter:card",
						content: "summary_large_image",
					},
					{
						name: `twitter:creator`,
						content: metaAuthor,
					},
					{
						name: `twitter:title`,
						content: metaTitle,
					},
					{
						name: `twitter:description`,
						content: metaDescription,
					},
				]
			}
		/>
	);
};

export default Seo;

import React from 'react';

import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { Link } from 'gatsby';

import '../styles/menu.scss';

import menuLinks from './menuLinks';

interface IProps {
  open: boolean;
}

const Menu: React.FC<IProps> = props => {
  const { open } = props;

  return (
    <div className={cx(
      `
        navbar-menu
        fixed md:static
        inset-x-0 md:inset-auto
        bottom-0 md:bottom-auto
        z-4
        bg-white md:bg-transparent
        md:flex md:items-center md:w-auto
        py-10 md:py-0
        overflow-auto
        font-serif
      `,
      {
        'block': open,
        'hidden': !open,
      })
    }>
      {
        menuLinks.map(item => (
          <Link
            to={item.href}
            key={uuid()}
            className='text-center md:text-left text-lg md:text-sm block md:inline-block uppercase px-6 py-2 hover:text-orange-500'
            activeClassName='text-orange-500'
          >
            {item.text}
          </Link>
        ))
      }
    </div>
  );
};

export default Menu;

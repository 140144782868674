import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import Logo from 'images/header/plendy-logo.inline.svg';

import { Menu, MenuToggler } from './components';

import './styles/index.scss';

interface IProps {
  variant?: string;
}

const Navbar: React.FC<IProps> = props => {
  const { variant } = props;

  // Menu toggle
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };

  // Navbar classes
  const landingClasses = '';
  const defaultClasses = 'bg-white border-b border-gray-300';
  const [classes, setClasses] = useState(defaultClasses);

  // Set classes depending on page scroll or open
  const handleScroll = () => {
    window.pageYOffset > 10 || open ?
      setClasses(defaultClasses) : setClasses(landingClasses);
  };

  // Only if Navbar has variant='landing'
  useEffect(() => {
    if (variant === 'landing') {
      setClasses(landingClasses);

      // On ititial load
      handleScroll();
      
      // On scroll
      window.onscroll = function () {
        handleScroll();
      };

    };
  }, [variant, open]);

  return (
    <nav className={`
      navbar
      fixed top-0 right-0 left-0 z-4
      flex items-center
      font-serif
      duration-200 ease-out
      ${classes}
    `}>
      <div className='container'>
        <div className='flex items-center justify-between flex-wrap'>
          <Link to='/'>
            {/* adjust logo in line with menu button */}
            <Logo className='transform translate-y-1' />
          </Link>
          <MenuToggler open={open} toggleMenu={toggleMenu} />
          <Menu open={open} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

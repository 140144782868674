import React from 'react';

import MenuIcon from 'images/header/menu-icon.inline.svg';
import MenuIconClose from 'images/header/menu-icon-close.inline.svg';

interface IProps {
  toggleMenu: () => void;
  open: boolean;
}

const MenuToggler: React.FC<IProps> = props => {
  const { toggleMenu, open } = props;

  return (
    <button
      className='btn-primary ml-auto px-2 py-1 mb-0 md:hidden'
      onClick={() => toggleMenu()}
    >
      <div className='flex justify-center mr-3 w-5'>
        {open ? <MenuIconClose /> : <MenuIcon />}
      </div>
      Menu
    </button>
  );
};

export default MenuToggler;
